import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { throttle } from 'throttle-debounce'
import { useEventListener, useMedia, useMetaData, usePrevious } from '../hooks'
import 'reset-css'
import 'focus-visible'
import Fonts from './fontface'
import fonts from '../fonts'
import '../styles/layout.css'
import Header from './header'
import Splash from './splash'
import { ScrollingPanel } from './panel'
import Slideshow from './slideshow'
import Information, { ContactPanel as Contact } from './information'
import Recognitions from './recognitions'

export default () => {
  const element = useRef(null),
        visibilityChanged = useRef(false),
        [panelsVisible, setPanelsVisible] = useState(false),
        prevVisible = usePrevious(panelsVisible),
        [activePanel, setActivePanel] = useState(`information`),
        lastScrollTop = useRef(null),
        { prismicHost: { data } } = useMetaData(),
        [isClient, setIsClient] = useState(false),
        transitionTimeout = useRef(null)

  useEffect(() => {
    setIsClient(true)

    // Load only in client due to window references
    // import('viewport-units-buggyfill').then(
    //   viewportUnitsBuggyfill => {
    //     window.viewportUnitsBuggyfill = viewportUnitsBuggyfill
    //     window.viewportUnitsBuggyfill.init({ force: true })
    // })
  }, [])

  const mobile = useMedia([
    `(max-width: 1000px)`
  ], [
    true
  ], false)

  const landscape = useMedia([
    `(orientation: landscape)`
  ], [
    true
  ], false)

  useEffect(() => {
    if(panelsVisible !== prevVisible) {
      if(transitionTimeout.current) clearTimeout(transitionTimeout.current)
      transitionTimeout.current = setTimeout(() => {
        document.documentElement.classList.remove('transitioning')
      }, 500)
    }
  }, [panelsVisible, prevVisible])

  const scrollListener = throttle(100, (e) => {
    if(visibilityChanged.current) return
    // Don't open panels on desktop as a result of scroll
    if(!mobile && !panelsVisible) return

    let clientHeight = document.documentElement.clientHeight,
        headerHeight = mobile ? 0 : 73,
        deltaY = e.deltaY || null,
        scrollingPanel,
        scrollingHeight,
        scrollingTop,
        scrollingComplete

    if(!panelsVisible) {
      scrollingHeight = document.scrollingElement.offsetHeight
      scrollingTop = document.scrollingElement.scrollTop
      scrollingComplete = scrollingTop >= scrollingHeight - 5
        || scrollingTop + clientHeight >= scrollingHeight - 5
    } else {
      scrollingPanel = document.querySelector(`.scrolling-panel`)
      scrollingHeight = scrollingPanel.scrollHeight
      scrollingTop = scrollingPanel.scrollTop
      // The last panel is padded substantially so we can subtract clientHeight / 2
      // or any similar amount to provide additional runway for the scroll effect
      scrollingComplete = scrollingTop + clientHeight - headerHeight >= scrollingHeight - clientHeight / 2
    }

    if(deltaY === null) {
      deltaY = scrollingTop - (lastScrollTop.current ? lastScrollTop.current : 0)
    }

    if(scrollingComplete && deltaY > 10) {
      if(!panelsVisible) {
        setPanelsVisible(true)
        visibilityChanged.current = true
        setTimeout(() => {
          visibilityChanged.current = false
        }, 1000)
      } else {
        setPanelsVisible(false)
        visibilityChanged.current = true
        setTimeout(() => {
          scrollingPanel.scroll({ top: 0 })
        }, 500)
        setTimeout(() => {
          visibilityChanged.current = false
        }, 1000)
      }
    }

    lastScrollTop.current = scrollingTop
  })

  // Watch scroll for panel open/close
  useEventListener('mousewheel', scrollListener, {
    element: element.current,
    passive: true
  })
  useEventListener('touchmove', scrollListener, {
    element: element.current,
    passive: true
  })

  const url = data.url,
        title = data.name,
        description = data.information.raw.map(section =>
          section.text.replace(/<br \/>/g, ``).replace(/(\n)+/g, ` `)
        ).join(` `),
        images = data.seo_images.filter(edge => edge).map(({ seo_image }) => [
          <meta key={`${seo_image.localFile.childImageSharp.fluid.src}-url`} name="og:image:secure_url" content={`${url}${seo_image.localFile.childImageSharp.fluid.src}`} />,
          <meta key={`${seo_image.localFile.childImageSharp.fluid.src}-width`} name="og:image:width" content={seo_image.dimensions.width} />,
          <meta key={`${seo_image.localFile.childImageSharp.fluid.src}-height`} name="og:image:height" content={seo_image.dimensions.height} />,
          seo_image.alt && (
            <meta key={`${seo_image.localFile.childImageSharp.fluid.src}-alt`} name="og:image:alt" content={seo_image.alt} />
          ),
        ]).reduce((a, c) => {
          return a.concat(c)
        }, [])

  return (
    <div ref={element} className="layout">
      <Helmet>
        {/* Fullscreen on iPhone X */}
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        {/* General metadata */}
        <html lang="en" amp={undefined} />
        <link rel="canonical" href={url} />
        <meta name="og:type" content="website" />
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        {images}
        {/* Favicons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#76b1e9" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ff0000" />
        {/* Documnet */}
        <html className={`${landscape ? `landscape` : ``} ${mobile ? `mobile` : ``} ${isClient ? `loaded` : ``} ${panelsVisible !== prevVisible ? `transitioning` : ``}`} data-panels={panelsVisible} data-active={activePanel} />
      </Helmet>
      <Fonts
        display="swap"
        fonts={fonts} />
      <Header
        mobile={mobile}
        panelsVisible={panelsVisible}
        setPanelsVisible={setPanelsVisible} />
      <div className="header-background"></div>
      <Splash />
      <Slideshow landscape={landscape} mobile={mobile} />
      <ScrollingPanel
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        panelsVisible={panelsVisible}
        landscape={landscape}
        layout={element.current}
        mobile={mobile}>
        {(mobile
          ? [
            <Contact key="contact" title="Contact" />
          ] : []).concat([
            <Information key="information" title="Information" />,
            <Recognitions key="recognitions" title="Recognitions" />
          ])}
      </ScrollingPanel>
    </div>
  )
}
